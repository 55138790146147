@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* COLORS */
$primary-color: #fafe28;
$primary-color-hover: #ffdd00;

:global(#app) {
	.partners {
		height: 280px;
		padding: 90px 40vh;
		background: #071c55;
		margin-top: 120px;

		.partnersColumn {
			background: #fff;
			border-radius: 10px;
			max-width: 172px;
			height: 100px;
			margin: 11.5px;
			display: inline-flex;
			align-items: center;

			.partnersDivImg {
				border-radius: 15px;
				position: relative;
				transition: all 0.5s;

				img {
					position: relative;
					transition: all 0.5s;

					&:hover {
						transform: scale(1.1);
					}
				}
			}
		}
	}
}
