@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* COLORS */
$primary-color: #fafe28;
$primary-color-hover: #fca702;

:global(#app) {
	.teamSingle {
		margin-left: 39.5vh;
		margin-right: 39vh;
		margin-top: 100px;
		margin-bottom: 150px;

		h4 {
			color: #1b1b1b;
			font-weight: 600;
			font-family: "Poppins", sans-serif;
			line-height: 1.2;
			font-size: 24px;
			font-style: normal;
		}

		p {
			margin-bottom: 10px;
			font-family: "Roboto", sans-serif;
			font-style: normal;
			font-size: 16px;
			font-weight: 400;
			color: #14212b;
			line-height: 1.8;
		}

		.teamSingleRow {
			margin-top: 50px;
		}

		.teamSingleSegment {
			padding: 30px;
			margin: 0;
			border-radius: 15px;
			box-shadow: 0 0 40px 5px rgb(0 0 0 / 5%);
			border: none;

			ul {
				padding: 0;
				margin-bottom: 0;

				li {
					list-style: none;
					display: inline-block;
					margin-right: 15px;
					margin-bottom: 0px;

					a {
						color: #051c55;
						font-size: 20px;
						transition: all 0.5s ease-in-out;

						&:hover {
							color: #fca702;
						}
					}
				}
			}

			.teamSingleSegmentDiv {
				color: #14212b;
				display: block;
				font-family: "Roboto", sans-serif;
				font-style: normal;
				font-size: 16px;
				font-weight: 400;
				line-height: 1.8;
				margin-bottom: 8px;

				.teamSingleIcon {
					color: #fca702;
					margin-right: 15px;
				}
			}

			h3 {
				font-size: 30px;
				color: #1b1b1b;
				margin: 0px;
				font-weight: 600;
				font-family: "Poppins", sans-serif;
				line-height: 1.2;
			}

			span {
				color: #fca702;
				margin: 0px;
				font-family: "Roboto", sans-serif;
				font-style: normal;
				font-size: 16px;
				font-weight: 600;
				line-height: 1.8;
			}

			p {
				margin-top: 12px;
				font-family: "Roboto", sans-serif;
				font-style: normal;
				font-size: 16px;
				font-weight: 400;
				color: #14212b;
				line-height: 1.8;
			}
		}

		img {
			// min-width: 300px;
			width: 100%;
			border-radius: 15px;
		}
	}
}
