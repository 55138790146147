div {
	.react-calendar {
		max-width: 292px;
		text-decoration: none;
		height: max-content;
		padding-bottom: 10px;
		border: none;
		border-radius: 8px;
		box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
		padding-left: 6px;

		padding-top: 1px;

		button {
			margin: 1px;
			width: 70px;
			border-radius: 5px !important;
		}

		&__tile {
			max-width: 38px;
			padding-top: 8px;
			text-align: center;
			line-height: 16px;
			background: #f6f6f6;
		}
		&__tile--active {
			background-color: #fca702 !important;
			border-radius: 5px !important;
			width: 38px !important;
			height: 31px !important;
		}

		&__month-view__days {
			margin-left: auto;
			margin-right: auto;
		}

		&__month-view__days__day {
			background: #f6f6f6;
			font-weight: normal;
			color: #454545;
			width: 100%;
			height: 31px !important;
			transition: all 0.3s ease-in-out;
		}

		&__tile--hasActive {
			background-color: rgba(11, 66, 50, 0.1) !important;
			color: #03a473 !important;
		}

		&__tile--now {
			border-radius: 0 !important;
			background: #fffa90;
		}

		&__navigation {
			margin-top: 5px;
			margin-right: 7px;
			background-color: #fca702;
			height: 35px;
			border-radius: 8px;

			button:enabled:hover {
				background-color: transparent;
				left: 3px;
			}

			button:enabled:focus {
				background-color: transparent;
			}

			&__label {
				background-color: red;
				font-family: Arial, Helvetica, sans-serif;
				font-size: 15px;
				font-weight: bold;

				&:hover {
					background-color: none;
				}
			}

			&__prev2-button,
			&__next2-button {
				display: none;
			}

			&__next-button,
			&__prev-button {
				font-size: 20px !important;
				background-color: transparent;
				color: #454545;
				transition: all 0.3s ease-in-out;

				&:hover {
					font-size: 22px !important;
					color: #0e0d0d;
				}
			}
		}
		abbr[title] {
			text-decoration: none;
		}

		&__month-view__weekdays {
			font-family: Arial, Helvetica, sans-serif;
			color: black;
			font-size: 0.9em;
			font-weight: bold;
			text-decoration: none;
			margin-left: 0px;
			margin-right: 8px;
		}
	}
}
