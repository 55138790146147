@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* COLORS */
$primary-color: #fafe28;
$primary-color-hover: #ffdd00;

:global(#app) {
	.galleryModal {
		margin-left: auto;
		margin-right: auto;

    .galleryModalContent{
      margin-left: auto;
      margin-right: auto;
    }
	}

	.galleryModalButton {
		position: absolute;
		width: 55px;
		height: 55px;
		padding: 0px;
		padding-right: 15px;
		line-height: 55px;
		background: #fca702;
		color: #fff !important;
		border-radius: 50px;
		top: 50%;
		left: 50%;
		font-size: 22px;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
		transition: all 0.5s ease-in-out;
		z-index: 200;

		.galleryModalIcon {
			position: absolute;
			top: 19px;
		}
	}
}
