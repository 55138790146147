@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* COLORS */
$primary-color: #fafe28;
$primary-color-hover: #fca702;

:global(#app) {
	.divLoader {
		padding: 100px;
    background: transparent !important;

		.loader {
			color: #fca702;
      background: transparent !important;
		}
	}
}
