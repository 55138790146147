@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* COLORS */
$primary-color: #fafe28;
$primary-color-hover: #ffdd00;

:global(#app) {
	.divCarouselItem {
		background-size: 100% 100vh;
		height: 85vh;
		width: 100%;
		padding-top: 300px;
		padding-bottom: 180px;
		background-position: center center !important;
		background-size: cover !important;
		background-repeat: no-repeat !important;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 1;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(9, 38, 82, 0.479);
			z-index: 2;
		}

		.carouselBlock {
      margin-top: -50px;
			margin-left: 40vh;
			margin-right: 90vh;
			z-index: 3;

			h6 {
				font-size: 20px;
				color: #fca702;
				font-weight: 600;
				position: relative;
				text-transform: uppercase;
				font-family: "Poppins", sans-serif;
			}

			h1 {
				font-family: "Poppins", sans-serif;
				line-height: 1.2;
				color: #fff;
				font-size: 60px;
				font-weight: 900;
				text-transform: uppercase;
				margin: 20px 0;
				font-style: normal;
			}

			p {
				color: #fff;
				font-size: 16px;
				font-family: "Roboto", sans-serif;
				line-height: 30px;
				font-weight: 400;
				margin-bottom: 20px;
			}

			.carouselBlockButtonLeft,
			.carouselBlockButtonRight {
				font-size: 16px;
				color: #ffffff;
				padding: 15px 25px;
				line-height: 1;
				transition: all 0.5s;
				text-transform: capitalize;
				position: relative;
				border-radius: 8px;
				text-align: center;
				overflow: hidden;
				z-index: 1;
				box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
				font-family: "Roboto", sans-serif;
				font-style: normal;
				font-size: 16px;
				font-weight: 400;
			}

			.carouselBlockButtonLeft {
				background-color: #fca702;
				border: 2px solid transparent;
				margin-right: 30px;

				&:hover {
					color: #fff;
					background: #061847;
				}
			}

			.carouselBlockButtonRight {
				background-color: transparent;
				border: 2px solid #fca702;

				&:hover {
					color: #fff;
					background: #061847;
					border: 2px solid transparent;
				}
			}
		}
	}
}
