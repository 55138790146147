@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

/* COLORS */
$primary-color: #fafe28;
$primary-color-hover: #ffdd00;

:global(#app) {
	.registerSegment {
		padding: 50px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 6vh;
		border: 1px solid #fff;
		border-radius: 10px;
		box-shadow: 0 0 40px 5px rgb(0 0 0 / 5%);
		height: max-content;
		width: 483px;

		.registerHeader {
			text-align: center;
			margin-bottom: 50px;

			img {
				width: 200px;
				margin-bottom: 10px;
			}

			p {
				font-size: 20px;
			}
		}

		.registerForm {
			label {
				font-weight: 400;
				font-family: "Roboto";
				font-style: normal;
				font-size: 16px;
				line-height: 1.8;
			}
			input {
				border: 1px solid #e8e8e8;
				border-radius: 8px;
				padding: 12px 18px;
				box-shadow: none;
				transition: 0.5s;
				height: 50px;

				&:focus {
					border: 1px solid #fca702;
				}

				&::placeholder {
					font-weight: 400;
					font-family: "Roboto";
					font-style: normal;
					font-size: 16px;
					line-height: 1.8;
					color: #6c757d;
				}
			}

			button {
				width: 100%;
				height: 51px;
				background: #fca702;
				color: #fff;
				padding: 12px 0;
				border: none;
				border-radius: 8px;
				font-weight: 600;
				box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
				transition: 0.5s;
				font-family: inherit;
				font-style: normal;
				font-size: 16px;
				line-height: 1.8;
				margin-top: 15px;
				transition: background-color 0.5s linear;

				&:hover {
					background: #051242;
				}

				.registerIcon {
					color: #ffffff;
				}
			}

			.registerCheckbox {
				font-size: 16px;
			}
		}

		.registerFooter {
			text-align: center;
			margin-top: 40px;
			font-size: 16px;
		}

		.registerLink {
			color: #fca702;
			transition: color 0.5s linear;

			&:hover {
				color: #14212b;
			}
		}
	}
}
