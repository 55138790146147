@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* COLORS */
$primary-color: #fafe28;
$primary-color-hover: #ffdd00;

:global(#app) {
	.destinationsDiv {
		margin-top: 130px;
		text-align: center;

		.destinationsSpan {
			text-transform: uppercase;
			font-weight: 600;
			font-size: 18px;
			color: #fca702;
			padding-left: 80px;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				width: 65px;
				height: 3px;
				left: 0;
				top: 8px;
				background: #fca702;
			}
		}

		h2 {
			font-weight: 700;
			text-transform: uppercase;
			font-size: 38px;
			color: #051242;
			margin: 10px 0;
			line-height: 1.2;
		}

		p {
			font-size: 16px;
			line-height: 1.8;
			margin-left: 70vh;
			margin-right: 70vh;
		}
	}
}
