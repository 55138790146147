@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* COLORS */
$primary-color: #fafe28;
$primary-color-hover: #ffdd00;

:global(#app) {
	.homeAboutDiv {
		margin-top: 110px;
		margin-left: 40.5vh;
		margin-right: 39vh;

		.homeAboutDivLeft {
			float: left;

			.homeAboutDivImg {
				border-radius: 15px;
				overflow: hidden;
				position: relative;
				transition: all 0.5s;
				width: 498.5px;
				height: 498.5px;
				margin-inline-end: 90px;

				img {
					width: 498.5px;
					height: 498.5px;
					border-radius: 15px;
					position: relative;
					transition: all 0.5s;
					z-index: 100;

					&:hover {
						transform: scale(1.1);
					}
				}
			}

			.homeAboutDivBlock {
				position: absolute;
				margin-top: -150px;
				margin-left: 20px;
				z-index: 100;
				background: #fca702;
				border-radius: 15px;
				text-align: center;
				width: 194px;
				height: 122px;
				padding-top: 23px;

				h5 {
					color: #fff;
					font-size: 45px;
					font-weight: 700;
					font-family: "Poppins", sans-serif;
					line-height: 0.9;
				}

				span {
					font-weight: 600;
					color: #051242;
					line-height: 0.5;
					text-transform: none;
				}
			}
		}

		.homeAboutDivRight {
			position: relative;
			display: block;
			padding: 10px 30px;

			span {
				text-transform: uppercase;
				font-weight: 600;
				font-size: 18px;
				color: #fca702;
				padding-left: 80px;
				position: relative;

				&::before {
					content: "";
					position: absolute;
					width: 65px;
					height: 3px;
					left: 0;
					top: 8px;
					background: #fca702;
				}
			}

			h2 {
				font-weight: 700;
				text-transform: uppercase;
				font-size: 38px;
				color: #051242;
				margin: 10px 0;
				font-family: "Poppins", sans-serif;
				line-height: 1.2;
			}

			p {
				font-family: "Roboto", sans-serif;
				font-style: normal;
				font-size: 16px;
				font-weight: 400;
				color: #14212b;
				line-height: 1.8;
			}

			ul {
				padding-top: 10px;

				li {
					position: relative;
					display: flex;
					align-items: center;
					list-style: none;
					padding-top: 10px;

					.homeAboutIcon {
						position: relative;
						display: flex;
						align-items: center;
						font-size: 20px;
						color: #fca702;
					}
				}
			}

			button {
				margin-top: 40px;
				width: 180px;
				height: 51px;
				font-size: 16px;
				color: #ffffff;
				padding: 15px 25px;
				line-height: 1;
				text-transform: capitalize;
				position: relative;
				cursor: pointer;
				border-radius: 8px;
				text-align: center;
				overflow: hidden;
				z-index: 1;
				background-color: #fca702;
				border: 2px solid transparent;
				box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
				transition: all 0.3s ease-in-out;
				text-decoration: none;
				font-family: "Roboto", sans-serif;
				font-style: normal;
				font-weight: 400;

				&:hover {
					color: #fff;
					background: #061847;
				}
			}
		}
	}
}
