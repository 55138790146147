@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* COLORS */
$primary-color: #fafe28;
$primary-color-hover: #ffdd00;

:global(#app) {
	.testimonialsItem {
		width: 363px;
		height: 284px;
		background: #fff;
		padding: 30px;
		border-radius: 15px;
		margin: 12px;
		position: relative;
		margin-bottom: 25px;
		box-shadow: 0 2px 14px rgb(0 0 0 / 8%);
		border: none;
		display: block;
		text-align: left;

		.testimonialsItemRating {
			display: block;
		}

		.testimonialsItemSpan {
			font-family: "Roboto", sans-serif;
			font-style: normal;
			font-size: 16px;
			font-weight: 400;
			color: #14212b;
			line-height: 1.8;
		}

		.testimonialsItemUser {
			margin-top: 30px;

			img {
				float: left;
				border-radius: 50%;
				margin-right: 20px;
				border: 2px solid #fca702;
				border-radius: 50px;
				padding: 5px;
			}

			.testimonialsItemBlock {
				text-align: left;
				position: absolute;
				top: 65%;
				left: 35%;

				h4 {
					color: #000055;
					font-size: 20px;
					margin: 0px;
					font-weight: 600;
					font-family: "Poppins", sans-serif;
					line-height: 1.2;
				}

				span {
					font-family: "Roboto", sans-serif;
					font-style: normal;
					font-size: 16px;
					font-weight: 400;
					color: #14212b;
					line-height: 1.8;
				}
			}
		}

		.testimonialsItemIcon {
			position: absolute;
			right: 30px;
			top: -32px;
			font-size: 50px;
			color: #fca702;
		}
	}
}
