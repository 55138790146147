@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* COLORS */
$primary-color: #fafe28;
$primary-color-hover: #ffdd00;

:global(#app) {
	.introVideoDiv {
		margin-top: 130px;
		text-align: center;

		.introVideoModalDiv {
			width: 500px;
			height: 300px;
		}
		.introVideoDivBlock {
			position: relative;
			display: flex;
			justify-content: center;
			border-radius: 5px;
			z-index: 100;

			.introVideoDivButton {
				z-index: 101;

				button {
					display: inline-block;
					padding: 0;
					height: 75px;
					width: 75px;
					text-align: center;
					background: #fff;
					position: absolute;
					border-radius: 50%;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 100;

					.introVideoIcon {
						display: inline-block;
						height: 75px;
						width: 75px;
						font-size: 20px;
						color: #fca702;
						position: absolute;
						top: 40%;
						left: 8%;
						transition: all 0.5s ease-in-out;

						&:hover {
							color: #051242;
						}
					}
				}
			}

			img {
				margin-left: 42vh;
				margin-right: 42vh;
				width: 1161px;
				height: 532px;
				border-radius: 12px;
			}
		}

		span {
			text-transform: uppercase;
			font-weight: 600;
			font-size: 18px;
			color: #fca702;
			padding-left: 80px;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				width: 65px;
				height: 3px;
				left: 0;
				top: 8px;
				background: #fca702;
			}
		}

		h2 {
			font-weight: 700;
			text-transform: uppercase;
			font-size: 38px;
			color: #051242;
			margin: 10px 0;
			line-height: 1.2;
		}

		p {
			font-size: 16px;
			line-height: 1.8;
			margin-left: 70vh;
			margin-right: 70vh;
			margin-bottom: 50px;
		}

		video {
			// width: 300px;
			// height: 100px;
		}
	}
}
