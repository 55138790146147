@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* COLORS */
$primary-color: #fafe28;
$primary-color-hover: #ffdd00;

:global(#app) {
	.counterGrid {
		margin-top: 130px;
		background: #fca702;
		position: relative;
		z-index: 1;
		padding-top: 50px;
		padding-bottom: 50px;
		padding-left: 41vh;
		padding-right: 40vh;
		text-align: center;

		.counterGridColumn {
			.counterGridColumnDiv {
				text-align: center;
				padding: 20px 35px 35px 35px;
				border-radius: 20px;
				position: relative;
				z-index: 1;
				border: 3px solid rgba(255, 255, 255, 0.3);
				width: 272px;
				height: 257px;

				div {
					.counterGridColumnDivIcon {
						color: #ffffff;
						font-size: 50px;
					}
				}

				span {
					display: block;
					line-height: 1;
					color: #fff;
					font-size: 50px;
					font-weight: 600;
					margin-top: 20px;
				}

				h6 {
					color: #232323;
					font-family: "Karla", sans-serif;
					margin-top: 15px;
					font-size: 18px;
					letter-spacing: -1px;
					margin-top: 10px;
				}
			}
		}
	}
}
