@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* COLORS */
$primary-color: #fafe28;
$primary-color-hover: #ffdd00;

:global(#app) {
	.ourBlog {
		margin-top: 130px;
		text-align: center;

		.ourBlogGrid {
			margin-left: 40vh;
			margin-right: 40vh;
			display: inline-block;
			text-align: left;
			margin-top: 30px;

			.ourBlogGridColumn {
				max-width: 371px;

				.ourBlogGridDivImg {
					border-radius: 15px;
					overflow: hidden;
					position: relative;
					transition: all 0.5s;

					img {
						position: relative;
						border-radius: 15px;
						box-shadow: 0 0 40px 5px rgb(0 0 0 / 5%);
						width: 371px;
						height: 278px;
						transition: all 0.5s;

						&:hover {
							transform: scale(1.1);
						}
					}
				}

				h5 {
					color: #051242;
					text-transform: capitalize;
					font-size: 22px;
					transition: all 0.5s ease-in-out;
					margin-top: 0;

					&:hover {
						color: #fca702;
					}
				}

				.ourBlogLink {
					font-weight: 600;
					color: #fca702;
					transition: all 0.5s ease-in-out;
					font-family: "Roboto", sans-serif;
					font-style: normal;
					font-size: 16px;
					line-height: 1.8;
					background-color: transparent;

					&:hover {
						color: #051242;
					}
				}

				.ourBlogGridColumnDiv {
					display: inline-block;
					margin-top: 10px;

					.ourBlogGridColumnLeft {
						float: left;
					}

					.ourBlogGridColumnRight {
						float: right;
						padding-left: 20px;
					}

					.ourBlogGridColumnLeft,
					.ourBlogGridColumnRight {
						.ourBlogIcon {
							color: #fca702;
							margin-right: 5px;
						}
					}
				}
			}
		}

		.ourBlogSpan {
			text-transform: uppercase;
			font-weight: 600;
			font-size: 18px;
			color: #fca702;
			padding-left: 80px;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				width: 65px;
				height: 3px;
				left: 0;
				top: 8px;
				background: #fca702;
			}
		}

		h2 {
			font-weight: 700;
			text-transform: uppercase;
			font-size: 38px;
			color: #051242;
			margin: 10px 0;
			line-height: 1.2;
		}

		p {
			font-size: 16px;
			line-height: 1.8;
			margin-left: 70vh;
			margin-right: 70vh;
		}
	}
}
